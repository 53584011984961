import React, { Component } from 'react';

const MyVar = window.myVar;
const site = 'superjob.ru';
const suffix = '.html';
const head = 'https://';

const Price = window.myVar.pricSJ;

export default class SJ extends Component {
    constructor(props) {
        super(props);

        this.state = {
            links: '',
            count: 0,
            price: 0,
            cost: 0,
            range: false,
            result: [],
        }
    }

    render() {
        const { links } = this.state;
        // console.log(price);
        return <React.Fragment>
            <div className="order-form-select-site-header">
                <h3>{MyVar.listLinks} {MyVar.sj}:</h3>
            </div>
            <div className="order-form-select-site-super">
                <textarea
                    className="text-area-by-site"
                    placeholder={ MyVar.sjTextareaPlaceholder }
                    value={links}
                    onChange={(e) => {
                        this.setState({ links: this.rendLink(e.target.value) });
                    }}
                ></textarea>
            </div>
        </React.Fragment>
    }

    rendLink(str) {

        if (!str) return '';

        const delim = '::::';
        let sjLinks = str.replace(/(\r|\n|\n\r)/, delim);

        sjLinks = sjLinks.replace(new RegExp(site, 'ig'), delim + site);
        sjLinks = sjLinks.replace(new RegExp(suffix, 'ig'), suffix + delim);

        sjLinks = sjLinks.split(delim);

        let res = [];

        for (var z in sjLinks) {
            if (sjLinks[z]) {
                if (new RegExp(site, 'i').test(sjLinks[z])) {
                    res.push(head + sjLinks[z]);
                }    
            }
        }
//сдклать удаление строк ссылок!!
        this.setState({ result: res }, () => {
            this.calculate();
        });
        
        res.push('');

        return res.join("\r");
    }

    calculate() {
        const { result } = this.state;

        if (typeof result !== 'object') return;

        let cnt = result.length - 1;
        let prc = 0;
        let cost = 0;
        let price = 0;
        let sets = false;

        let prcKey = Object.keys(Price);

        for (var z in prcKey) {
            let now = +prcKey[z];
            let next = +prcKey[+z + 1];
            let ppr = Price[now];

            if (!sets) {
                if (next) {

                    if (now <= cnt && next > cnt) {
                        cost = (ppr || 0);
                        prc = cost * cnt;
                        price = ppr;
                        sets = true;
                    }

                } else {
                    cost = (ppr || 0);
                    prc = cost * cnt;
                    price = ppr;
                    sets = true;
                }
            }
        }

        this.setState({
            count: cnt,
            price: prc,
            cost: price,
        }, () => {
                this.props.onCalculate(this.state, 'sj');
        }); 

        
    }
}