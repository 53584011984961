import React, { Component } from 'react';

import { Upload, Icon, message } from 'antd';

const MyVar = window.myVar;
const Domain = MyVar.url;
const Price = MyVar.pricHH;
const Accept = MyVar.accept;
const FileMaxSize = 5242880;


export default class HH extends Component {
    constructor(props) {
        super(props);

        this.state = {
            count: 0,
            price: 0,
            cost: 0,
            links: [],
            range: false,
        }
    }

    render() {
        const { links, range } = this.state;
        const { showLoader, hideLoader } = this.props;

        return <React.Fragment>
            <div className="order-form-select-site-header">
                <h3>{MyVar.fileList} { MyVar.hh }:</h3>
            </div>
            <div className="order-form-select-site-head">
                <Upload.Dragger
                    multiple={true}
                    accept={Accept}
                    action={Domain + '/upload.php'}
                    onChange={({ fileList }) => {
                        const now = [];
                        const all = (fileList || []).length;
                        let nowCNT = 0;
                        showLoader();
                        for (var z in fileList) {
                            if (fileList[z].status === "done") {
                                if (fileList[z].size <= FileMaxSize) {
                                    now.push(fileList[z])
                                } else {
                                    if (!fileList[z].showMess) {
                                        fileList[z].showMess = 1;
                                        fileList[z].response = MyVar.fileText + ' "' + fileList[z].name + '" ' + MyVar.fileSizeLimit;
                                        fileList[z].status = 'error';
                                        message.error(fileList[z].response);
                                    }
                                }
                                nowCNT++;
                            } else if (fileList[z].status === "error") {
                                nowCNT++;
                            }
                        }

                        if (nowCNT === all) {
                            hideLoader();
                        }

                        this.setState({ links: now }, () => {
                            this.calculate();
                        })
                    }}
                    onRemove={(file) => {
                        // console.log('REMOVE', file);
                        const newLink = [];
                        for (var z in links) {
                            if (links[z].response.fileName !== file.response.fileName) {
                                newLink.push(links[z]);
                            }
                        }
                    }}
                >
                    <p className="ant-upload-drag-icon"><Icon type="inbox" /></p>
                    <p className="ant-upload-text">{ MyVar.uploadDummy }</p>
                    <p className="ant-upload-hint">{ MyVar.uploadAcceptFiles } {Accept.replace(/,/g, ', ')}</p>
                </Upload.Dragger>
            </div>
        </React.Fragment>
    }

    calculate() {

        const { links } = this.state;

        if (typeof links !== 'object') return;

        let cnt = links.length;
        let prc = 0;
        let cost = 0;
        let price = 0;
        let sets = false;

        let prcKey = Object.keys(Price);

        if (cnt) {
            for (var z in prcKey) {

                let now = +prcKey[z];
                let next = +prcKey[+z + 1];
                let ppr = Price[now];

                if (!sets) {
                    if (next) {

                        if (now <= cnt && next > cnt) {
                            cost = (ppr || 0);
                            prc = cost * cnt;
                            price = ppr;
                            sets = true;
                        }

                    } else {
                        cost = (ppr || 0);
                        prc = cost * cnt;
                        price = ppr;
                        sets = true;
                    }
                }
            }
        }


        this.setState({
            count: cnt,
            price: prc,
            cost: price,
        }, () => {
            this.props.onCalculate(this.state, 'hh');
        });
    }
}