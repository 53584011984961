import React, { Component } from 'react';
import { Input, Button, message } from 'antd';
import SJ from './SJ';
import HH from './HH';

const axios = require('axios').default;
const MyVar = window.myVar;
const URL = MyVar.url;
const onSuccess = MyVar.onOk;


export default class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            policeCheck: false,
            emailValue: '',//'beloff1985@ya.ru',
            cost: 0,
            costSJ: 0,
            costHH: 0,
            count: 0,
            countHH: 0,
            countSJ: 0,
            price: 0,
            priceHH: 0,
            priceSJ: 0,
            range: false,
            SJ: null,
            HH: null,
        };
    }

    defState() {

        let state = {
            step: 0,
            loading: false,
            policeCheck: false,
            emailValue: '',
            cost: 0,
            costSJ: 0,
            costHH: 0,
            count: 0,
            countHH: 0,
            countSJ: 0,
            price: 0,
            priceHH: 0,
            priceSJ: 0,
            range: false,
            SJ: null,
            HH: null,
        }

        this.setState(state); 
    }

    render() {

        const { step, loading } = this.state;

        return <div className="vertical none size-default">
            <div className={"loading" + (loading ? ' loading-show' : '')}><span className="loading-border"></span></div>
            <div
                className="head gradient"
                style={{
                    textAlign: 'center',
                    padding: '20px 30px',
                    backgroundColor: 'rgb(52, 152, 243)',
                    borderColor: 'rgb(23, 54, 82)',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: 6
                }}
            >
                <div className="textable">
                    <p
                        style={{
                            fontFamily: "Lobster, 'PT Sans', Helvetica, Arial, sans-serif",
                            fontSize: 26,
                            fontWeight: 400,
                            color: '#fff'
                        }}
                    >Форма заказа</p>
                </div>

            </div>
            <div className="formabk">
                {step === 0 && this.firstScreen()}
                {step === 1 && this.secondScreen()}
                {step === 2 && this.sjScreen()}
                {step === 3 && this.hhScreen()}

                {(step === 2 || step === 3) &&
                    <div className="order-form-prices">
                    {this.getFooter()}
                    <div className="order-form-buttons">
                        <span className="order-form-back">
                            <button
                                onClick={() => this.__setStep(1)}
                            >{MyVar.back}</button>
                        </span>
                        <span className="order-form-back">
                            <button
                                onClick={() => this.__Save()}
                            >{MyVar.order}</button>
                        </span>
                    </div>
                </div>
                }
            </div>
            
        </div>
    }

    showLoading() {
        this.setState({ loading: true });
    }
    hideLoading() {
        this.setState({ loading: false });
    }

    getFooter() {
        const { cost, count, price, countHH, countSJ, priceHH, priceSJ, costSJ, costHH } = this.state;

        const withHH = countHH ? ' withHH' : '';
        const withJS = countSJ ? ' whitSJ' : '';

        return <React.Fragment>
            <div className="statistic-sj">
                <span className={"order-form-cost-sj-items" + withHH + withJS}>Стоимость {MyVar.sj}: <b>{costSJ} руб.</b></span>
                <span className={"order-form-cnt-sj-items" + withHH + withJS}>Количество {MyVar.sj}: <b>{countSJ} шт.</b></span>
                <span className={"order-form-price-sj-items" + withHH + withJS}>Сумма {MyVar.sj}: <b>{priceSJ} руб.</b></span>
            </div>
            <div className="statistic-hh">
                <span className={"order-form-cost-hh-items" + withHH + withJS}>Стоимость {MyVar.hh}: <b>{costHH} руб.</b></span>
                <span className={"order-form-cnt-hh-items" + withHH + withJS}>Количество {MyVar.hh}: <b>{countHH} шт.</b></span>
                <span className={"order-form-price-hh-items" + withHH + withJS}>Сумма {MyVar.hh}: <b>{priceHH} руб.</b></span>
            </div>
            <div className="statistic-all">
                <span className={"order-form-cost-all-items" + withHH + withJS}>Стоимость: <b>{cost} руб.</b></span>
                <span className={"order-form-cnt-all-items" + withHH + withJS}>Количество: <b>{count} шт.</b></span>
                <span className={"order-form-price-all-items" + withHH + withJS}>К оплате: <b>{price} руб.</b></span>
            </div>
        </React.Fragment> 
    }

    sjScreen() {
        const { range } = this.state;
        
        return <React.Fragment>
            <SJ
                ref="SJ"
                onCalculate={(calc, sr) => this.allCalculate(calc, sr)}
            />
            <div className={"extra-block" + (range && ' opened' || '')}>
                <div
                    className="open-extra-block"
                    onClick={() => { this.setState({ range: !range }) }}
                >{!range ? "Добавить файлы " + MyVar.hh : 'Скрыть блок'}</div>
                <HH
                    ref="HH"
                    onCalculate={(calc, sr) => this.allCalculate(calc, sr)}
                    showLoader={ () => this.showLoading() }
                    hideLoader={ () => this.hideLoading() }
                />
            </div>
        </React.Fragment>
    }

    hhScreen() {
        const { range } = this.state;
        
        return <React.Fragment>
            <div className="order-form-select-site-header">
                <h3>Загрузите резюме {MyVar.hh}:</h3>
            </div>
            <div className="order-form-select-site">
                <HH
                    ref="HH"
                    onBack={() => this.__setStep(1)}
                    onCalculate={(calc, sr) => this.allCalculate(calc, sr)}
                    showLoader={ () => this.showLoading() }
                    hideLoader={ () => this.hideLoading() }
                />
            </div>
            <div className={"extra-block" + (range && ' opened' || '')}>
                <div
                    className="open-extra-block"
                    onClick={() => { this.setState({ range: !range }) }}
                >{!range ? "Добавить ссылки с " + MyVar.sj  : 'Скрыть блок'}</div>
                {
                    <SJ
                        ref="SJ"
                        onCalculate={(calc, sr) => this.allCalculate(calc, sr)}
                    />
                }
            </div>
        </React.Fragment>
    }

    allCalculate( params, service ) {

        let st = {};
        st[service] = params;

        this.setState(st, () => {
            this.calculate();
        });
    }

    calculate() {

        // console.log('calculate', this);

        const { HH, SJ } = this.refs;

        if (!HH && !SJ) return;

        const calc = {
            cost: 0,
            costSJ: 0,
            costHH: 0,
            countHH: 0,
            countSJ: 0,
            count: 0,
            priceHH: 0,
            priceSJ: 0,
            price: 0,
            price: 0,
            delta: 0,
        }

        if (HH) { 
            let st = HH.state; 
            calc.cost += st.cost || 0;
            calc.costHH += st.cost || 0;
            calc.count += st.count || 0;
            calc.countHH += st.count || 0;
            calc.price += st.price || 0;
            calc.priceHH += st.price || 0;
            calc.delta++;
        }

        if (SJ) { 
            let st = SJ.state || 0; 
            calc.cost += st.cost || 0;
            calc.costSJ += st.cost || 0;
            calc.count += st.count || 0;
            calc.countSJ += st.count || 0;
            calc.price += st.price || 0;
            calc.priceSJ += st.price || 0;
            calc.delta++;
        }

        calc.cost = calc.count ? Math.round(calc.price / calc.count ) : 0;


        this.setState( calc );

        // console.log('calc', calc );
    }
    secondScreen() { 
        // const { } = this.state;

        return <React.Fragment>
            <div className="order-form-select-site-header">
                <h3>Выберите сайт:</h3>
            </div>
            <div className="order-form-select-site">
                <Button
                    className="btn btn-event font-text gotosjsite"
                    onClick={() => this.__setStep(2)}
                >{MyVar.sj}</Button>
                <Button
                    className="btn btn-event font-text gotohhsite"
                    onClick={() => this.__setStep(3)}
                >{ MyVar.hh }</Button>
                <Button
                    className="btn btn-event font-text backoff"
                    onClick={() => this.__setStep(0)}
                >{ MyVar.back }</Button>
            </div>
        </React.Fragment>
    }

    firstScreen() {
        const { policeCheck, emailValue } = this.state;
        
        return <React.Fragment>
            <div className="order-form-email">
                <Input
                    addonBefore={ MyVar.emailMessFromResponse }
                    placeholder={ MyVar.emailExemple }
                    type="email"
                    value={ emailValue }
                    onChange={(e) => { 
                        // console.log('cdscsdcsdcdsdcs', e);
                        this.setState({ emailValue: e.target.value.trim() })
                    }}
                />
            </div>
            <div className="order-form-button">
                <Button
                    className="btn btn-event font-text submit"
                    onClick={() => this.__setStep( 1 ) }
                    disabled={ !this._validEmail(emailValue) } // << РАЗБЛОКИРОВАТЬ
                >{ MyVar.orderItem }</Button>
            </div>
            <div className="order-form-police-success">
                {MyVar.politicText} <a href="/police.html" target="_blank">{MyVar.politicLink}</a>
            </div>
        </React.Fragment>
    }

    _validEmail(email = '') {
        const reg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
        return reg.test(email);
    }

    __Save() {
        const { SJ, HH } = this.refs;
        const {
            emailValue, cost, count, price, countHH, countSJ, priceHH, priceSJ, costSJ, costHH
        } = this.state;


        if (!count) {
            message.warning('Укажите ссылки или файлы для оформления заказа');
            return;
        }


        const post = {
            emailValue, cost, count, price, countHH, countSJ, priceHH, priceSJ, costSJ, costHH,
            links: {},
            files: {},
        };

        if (countSJ) {
            for (var z in SJ.state.result) {
                if (SJ.state.result[z]) {
                    post.links[z] = SJ.state.result[z];
                }
            }
        }

        if (countHH) {
            for (var z in HH.state.links) {
                if (HH.state.links[z]) {
                    post.files[z] = HH.state.links[z].response;
                }
            }
        }

        let formData = new FormData();

        const getV = function (i, k) {
            
            for (var z in i) {
                
                switch (z) {
                    case 'links':
                        formData.append(z, JSON.stringify(i[z]));
                        break;
                    case 'files':
                        formData.append(z, JSON.stringify(i[z]));
                        break;
                    default:
                        formData.append(z, i[z]);
                }

            }

            // console.log('FORM::', formData);
        }
        

        getV(post);

        this.showLoading();

        let response = axios({
            url: URL + '/' + 'accept-order.php',
            method: 'post',
            data: formData, //JSON.stringify( post ),
            responseType: 'stream',
        });


        // response.then(response => response.json())
        response.then((res) => {
            onSuccess && onSuccess(post, res);
            this.defState();
            this.hideLoading();
        })

        // console.log('SAVE::', post);
    }

    __setStep(step) {
        if (!step) {
            step = 0;
        }

        this.setState({ step: step })
    }
}